footer {
    background-color: #00011d;
    color: white;
    text-align: center;
    padding: 10px;
    bottom: 0;
    width: 1vp;
    z-index: 100;
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
}

[data-theme="light"] footer {
    background-color: #f8f9fa;
    color: black;
    border-top: 1px solid #e9ecef;
}