.container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  @media screen and (max-width: 768px) {
    .container {
      gap: 1px;
    }

  }
  
  .flagContainer {
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
  
  p {
    margin: 0;
    text-align: left;
  }
  