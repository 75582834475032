.slide{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 10% 10px 0 60px;
    cursor: pointer;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0;
    margin: 0;
}

.text h1 {
    color: #1011fa;
    margin: 0 0 10px 0;
    font-size: 1.8em;
}

[data-theme="dark"] .text h1 {
    color: #1011fa !important;
}

.text h4 {
    margin: 0 0 10px 0;
}

[data-theme="dark"] .text {
    color: white;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.logo img {
    border-radius: 20%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

[data-theme="dark"] .logo img {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}