.slide{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 6% 60px 0 60px;
    cursor: pointer;
}

.text {
    display: flex;
    flex-direction: column;
}

.text h1 {
    margin: 80px 0 10px 0;
    font-size: 1.6em;
    color : #1011fa;
}

[data-theme="dark"] .text p {
    color: white;
}

[data-theme="dark"] .text h1 {
    color: #1011fa !important;
}

.photos {
    display: flex;
    position: relative;
    padding: 70px 50px 40px 16px !important;
}

.photo1 {
    justify-content: start;
    object-fit: cover;
    height: 88%;
    position: relative;
    clip-path: polygon(0 0, 66% 0, 90% 100% , 0 100%);
}

.photo2 {
    object-fit: fill;
    height: 42.5%;
    position: relative;
    z-index: 1;
    right: 28%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
}

.photo3 {
    object-fit: fill;
    height: 42.5%;
    position: relative;
    z-index: 1;
    top: 45.5%;
    right: 70.5%;
    clip-path: polygon(26% 0, 100% 0, 100% 100%, 50% 100% );
}


