@font-face {
  font-family: "Roboto Mono", monospace;
  src: url("fonts/RobotoMono-VariableFont_wght.ttf") format("truetype");
}

:root {
  --background-image: url('assets/images/pattern.svg');
  --background-color: #fff;
}

[data-theme="dark"] {
  --background-image: url('assets/images/pattern_dark.svg');
  --background-color: rgb(0,3,35);
  --text-color: white;
}

html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: "Roboto Mono", monospace;
  background-color: var(--background-color);
  background-image: var(--background-image);
  background-repeat: repeat;
  background-size: auto;
}

.desktop-nav {
  display: none;
}

.mobile-nav {
  display: block;
}

@media (min-width: 768px) {
  .desktop-nav {
    display: block;
  }

  .mobile-nav {
    display: none;
  }
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
