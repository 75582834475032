.cardcontainers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

.cardcontainers h4 {
    color: #1011F5;
  }

@media (max-width: 768px) {
  .buttons {
    display: flex;
    flex-direction: column;
  }
}

.techIcons{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  justify-content: left;
  padding: 25px;
}

.buttons .readButton {
  background-color: transparent;
  color: #1011F5;
  border-color: #1011F5;
}

.buttons .readButton:hover {
  background-color: transparent;
  color: #0d0fb8;
  border-color: #0d0fb8;
}

.buttons Button {
  margin: 10px;
  background-color: #1011F5;
}

.buttons Button:hover {
  background-color: #0d0fb8;
}

