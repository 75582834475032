.container {
    display: flex;
    justify-content: center;
    padding-left: 24%;
    padding-right: 24%;
  }

  [data-theme="dark"] .container {
    color: white;
  }
  
  .content {
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    .container {
      padding-left: 2%;
      padding-right: 1%;
    }
    
  }
  