.preview {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    overflow: hidden;
    height: 30vh;
    position: relative;
    width: 100vw;
    padding-left: 10px;
    padding-bottom: 10px;
}

.buttonOverlay {
    position: relative;
    left: 90%;
    bottom: 125px;
    transform: translateY(-50%);
    z-index: 10;
  }

.linedHeader {
    display: inline-block;
    position: relative;
}

.preview img {
    height: 100%;
    flex-grow: 1;
    min-width: 20%;
    object-fit: cover;
}

.preview::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}

[data-theme = "dark"] .preview::before {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 1, 35, 1) 100%);
}

[data-theme = "dark"] h1 {
    color: white;
}