.summary {
    font-family: "Roboto Mono", monospace;
    text-align: justify;
}

.summary h2 {
  text-align: left;
}

[data-theme="dark"] { 
  .summary {
    color: white;
}
 .summary h1 {
    color: #1011F5;
}
}

.summary h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #1011F5;
    text-align: left;
    padding-left: 0;
}

.summary Button {
  margin: 10px;
  background-color: #1011F5;
}

.summary Button:hover {
  background-color: #0d0fb8;
}

.mobilePortrait {
  display: none;
}

.summary .portrait {
  display: block;
}

@media screen and (max-width: 900px) {
  .summary .portrait {
    display: none;
  }

  .mobilePortrait {
    display: block;
  }
  
}

.portrait {
  width: 60%;
  height: auto;
  border-radius: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}

.portrait img {
  width: 100%;
  height: auto;
  border-radius: 18%;
}

.portrait::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 18%;
  background-image: conic-gradient(from var(--angle), #1101fa,#ffffff, #1101fa);
  top: 49.7%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  padding: 0.15rem;
  animation: 3s spin linear infinite;
}

.portrait::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 18%;
  background-image: conic-gradient(from var(--angle), #1101fa,#ffffff, #1101fa);
  top: 0;
  left: 0;
  filter: blur(1rem);
  opacity: 0.7;
  z-index: -1;
  animation: 3s spin linear infinite;
}

@keyframes spin {
  0% {
    --angle: 0deg;
  }
  100% {
    --angle: 360deg;
  }
}

@property --angle{
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blinkingCursor::after {
    content: "|";
    animation: blink 1.2s step-start infinite;
    margin-left: 0px;
    vertical-align:text-bottom;
  }