.projects {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
  align-items: flex-start;
  width: 85%;
  max-width: 85%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .projects {
    width: 50%;
    max-width: 50%;
  }
}

.summary {
  width: 85%;
  max-width: 85%;
  text-align: left;
}

[data-theme="dark"] .summary {
  color: white;
}

@media (min-width: 768px) {
  .summary {
    width: 45%;
    max-width: 45%;
  }
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

h1 {
  padding: 0;
}