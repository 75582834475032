.resumeItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 0;
    margin-bottom: 0;
}

.resumeContent {
    flex: 1;
    padding: 0 15px;
    max-width: 80%;
}

.resumeContent h3 {
    margin: 0 0 5px 0;
}

.resumeContent span {
    display: block;
    margin: 5px 0;
    color: #666;
}

.resumeContent p {
    margin: 10px 0 0 0;
    text-align: justify;
    max-width: 100%;
    word-wrap: break-word;
}

.resumeContent h4 {
    color: #1011F5
}

.resumeLogo {
    display: flex;
    justify-content: center;
    width: 90px;
    height: 90px;
    flex-shrink: 0;
}

.clickableLogo {
    cursor: pointer;
}

.resumeLogo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.placeholderLogo {
    width: 100px;
    height: 100px;
    background-color: transparent;
}

@media (max-width: 768px) {
    .resumeLogo {
        width: 60px;
        height: 60px;
    }

    .resumeLogo img,
    .placeholderLogo {
        width: 60px;
        height: 60px;
    }

    .resumeContent {
        padding: 0 5px;
    }

    .resumeContent p {
        text-align: left;
    }
}

.techIcons{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    justify-content: left;
    padding: 28px;
}