.slideshow {
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 500px;
    margin: auto;
    overflow: hidden;
    margin: 75px auto;
    border-radius: 3%;
    background-color: #f8f9fa;
  }

  [data-theme="dark"] .slideshow {
    background-color: rgb(0, 3, 51);
}
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease;
    pointer-events: none;
  }
  
  .slide.active {
    opacity: 1;
    pointer-events: auto;
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    transform: translateY(-50%);
    z-index: 1;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .progressBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
  }

  [data-theme="dark"] .progressBar {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .progress {
    height: 100%;
    background-color: #00000062;
    transition: width 0.1s linear;
  }

[data-theme="dark"] .progress {
    background-color: #1011fa62;
}
  