.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.page Button {
    margin-top: 5rem;
} 

.page form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin-top: 1rem;
}

.page h2 {
    display: flex;
    color: white;
}