[data-theme="dark"] .container {
    color: white;
}

:root {
    --map-light: url('../assets/images/worldmap.svg');
    --map-dark: url('../assets/images/worldmap_dark.svg');
  }
  
  [data-theme="light"] {
    --map-image: var(--map-light);
  }
  
  [data-theme="dark"] {
    --map-image: var(--map-dark);
  }

.countries {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-left: 5rem;
}

.continents {
    padding-left: 5rem;
}

.continents p {
    font-size: 1rem;
    text-align: center;
}

@media (max-width: 768px) {
    .countries {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
